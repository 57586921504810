<template>
  <div id="Facilities">
    <h1 class="brand-color-dark  headline">Prüfer & Signale</h1>
    <!-- <h1 class="brand-color-dark  headline">Streckenverzeichnis</h1> -->
        
    <NoDataBox v-if="data.length <= 0 && !isLoading" headline="Keine Informationen vorhanden">Schaue später wieder vorbei.</NoDataBox>

    <!-- <p v-show="isLoading">
      <font-awesome-icon icon="spinner" class="icon" pulse /> Daten werden geladen...
    </p> -->
<!-- 
    <div class="content-card-container grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-4 my-3 items-start" v-if="data">
      <FacilityItem :data="d" v-for="d in data" :key="d.id"></FacilityItem>
    </div> -->

    <div class="rounded-md bg-card-content p-6">
      <ol class="relative border-l border-blue-900 ml-5">  
        <li class="mb-10 ml-6">            
          <span class="flex absolute -left-3 justify-center items-center w-6 h-6  rounded-full ring-8 ring-gray-900 bg-blue-900">
            <font-awesome-icon icon="map-marker" class="icon w-5 h-5 text-blue-400"/>
          </span>
          <h3 class="flex items-center mb-1 text-lg font-semibold text-white">Haltestelle Neumarkt</h3>
          <p class="block mb-2 text-sm font-normal leading-none text-gray-500">Kürzel: NEU</p>
          <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 3</span> <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 4</span> <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 16</span> <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 18</span>
        </li>                
        <li class="mb-10 ml-6">            
          <span class="flex absolute -left-3 justify-center items-center w-6 h-6  rounded-full ring-8 ring-gray-900 bg-blue-900">
            <font-awesome-icon icon="traffic-light" class="icon w-5 h-5 text-blue-400"/>
          </span>
          <h3 class="flex items-center mb-1 text-lg font-semibold text-white">Signal 301</h3>
          <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 3</span> <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 4</span> <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 16</span> <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 18</span>
        </li>
        <li class="mb-10 ml-6">            
          <span class="flex absolute -left-3 justify-center items-center w-6 h-6  rounded-full ring-8 ring-gray-900 bg-blue-900">
            <font-awesome-icon icon="tachometer-alt" class="icon w-5 h-5 text-blue-400"/>
          </span>
          <h3 class="flex items-center mb-1 text-lg font-semibold text-white">Prüfer 50 km/h</h3>
          <p class="block mb-2 text-sm font-normal leading-none text-gray-500">Signal 301</p>
          <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 3</span> <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 4</span> <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 16</span> <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 18</span>
        </li>
        <li class="mb-10 ml-6">            
          <span class="flex absolute -left-3 justify-center items-center w-6 h-6  rounded-full ring-8 ring-gray-900 bg-blue-900">
            <font-awesome-icon icon="tachometer-alt" class="icon w-5 h-5 text-blue-400"/>
          </span>
          <h3 class="flex items-center mb-1 text-lg font-semibold text-white">Prüfer 30 km/h</h3>
          <p class="block mb-2 text-sm font-normal leading-none text-gray-500">Signal 301</p>
          <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 3</span> <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 4</span> <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 16</span> <span class="text-sm font-medium mr-1 px-2.5 py-0.5 rounded bg-blue-200 text-blue-800 ml-1">Linie 18</span>
        </li>
      </ol>
    </div>


  </div>
</template>


<script>
import NoDataBox from "@/components/NoDataBox";
import FacilityItem from "@/components/FacilityItem";

import { mapGetters } from "vuex";

export default {
  name: "Prüfer & Signale",
  components: {
    NoDataBox,
    FacilityItem
  },
  data() {
    return {
      data: [],
      isLoading: true
    };
  },
  methods: {},
  mounted() {
    this.$store.dispatch("fetchUserProfile");

    // this.$store.dispatch("fetchBoardFacilities").then((a) => {
    //   console.log(a);

    //   if (a.status == true) {
    //     this.data = a.result
    //   }

    //   this.isLoading = false
    // });
  },
  computed: {
    ...mapGetters({
      user: "getUser"
    }),
  },
};
</script>